import styled from '@emotion/styled';
import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedTextFieldProps,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ClassificationTag, Merchant } from '../../../../services/FinaloopGql';
import { MerchantSelector } from '../../../common/MerchantSelector';
import { MultiLineTextInput } from '../../../common/MultiLineTextInput/MultiLineTextInput';
import { Toast } from '../../../common/Toast';
import { useDispatchBusinessEventsStatus } from '../../../common/hooks/useDispatchBusinessEventsStatus';
import { useAccountingTypeFinder } from '../../hooks/useAccountingTypeFinder';
import { useFixbooksApi } from '../../hooks/useFixbooksApi';
import { AccountingType, AccountingTypes, BusinessEvent, FixbooksAction, FixbooksEventRequest } from '../../types';
import { getEmptyAction } from '../../utils';
import { ActionButtons } from './ActionButtons';
import { EventEditor } from './EventEditor';
import { keyBy } from 'lodash';

type BusinessEventsDispatcherViewProps = {
  isDatePickerVisible?: boolean;
  companyId: string;
  classificationTags?: ClassificationTag[];
};

const SUCCESS_MESSAGE = 'Business event sent successfully!';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const HorizontalFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const EditorsWrapper = styled(VerticalFlex)`
  gap: 10px;
`;

const getEmptyFixbooksRequest = (companyId: string): FixbooksEventRequest => ({
  companyId,
  accrualActions: [getEmptyAction(), getEmptyAction()],
  cashActions: [getEmptyAction(), getEmptyAction()],
  eventTimeMs: Date.now(),
  context: undefined,
  merchant: undefined,
  eventId: '',
  classificationTagId: undefined,
});

const getEmptyActions = () => [getEmptyAction(), getEmptyAction()];

export const BusinessEventsDispatcherView = (props: BusinessEventsDispatcherViewProps) => {
  const [selectedAccountingType, setSelectedAccountingType] = useState<AccountingType>('Both');
  const [selectedContextType, setSelectedContextType] = useState<'merchant' | 'text' | 'both'>('text');
  const { isDatePickerVisible, companyId, classificationTags } = props;
  const [isDispatching, setIsDispatching] = useState(false);
  const [isShowingMemo, setIsShowingMemo] = useState(false);
  const [cashActions, setCashActions] = useState<FixbooksAction[]>(getEmptyActions());
  const [accrualActions, setAccrualActions] = useState<FixbooksAction[]>(getEmptyActions());
  const [fixbooksEventRequest, setFixbooksEventRequest] = useState<FixbooksEventRequest>(
    getEmptyFixbooksRequest(companyId),
  );
  const [hasCollisionWithinDifferentCashAccrualActions, setHasCollisionWithinDifferentCashAccrualActions] = useState(
    false,
  );
  const { usePostFixbooksEvent } = useFixbooksApi();
  const { postFixbooksEvent } = usePostFixbooksEvent();
  const { getAccountingType } = useAccountingTypeFinder();
  const {
    showSuccess,
    dispatchError,
    onDispatchError,
    onDispatchSuccess,
    setDispatchError,
  } = useDispatchBusinessEventsStatus();

  const clearActions = () => {
    setCashActions(getEmptyActions());
    setAccrualActions(getEmptyActions());
  };

  const clearAllData = useCallback(() => {
    setIsShowingMemo(false);
    setSelectedAccountingType('Both');
    setFixbooksEventRequest(getEmptyFixbooksRequest(companyId));
    clearActions();
  }, [companyId]);

  useEffect(() => {
    clearAllData();
  }, [companyId]);

  const onSaveClicked = useCallback(
    (shouldClearForm: boolean) => {
      setDispatchError(null);
      setIsDispatching(true);

      const selectedDateInStartOfDay = moment(fixbooksEventRequest.eventTimeMs)
        .startOf('day')
        .valueOf();
      const request: FixbooksEventRequest = {
        ...fixbooksEventRequest,
        eventTimeMs: selectedDateInStartOfDay,
      };
      if (!isShowingMemo) request.memo = undefined;

      if (selectedAccountingType === 'Cash') request.accrualActions = [];
      if (selectedAccountingType === 'Accrual') request.cashActions = [];

      postFixbooksEvent({ request })
        .then(() => {
          if (shouldClearForm) clearAllData();
          onDispatchSuccess();
        })
        .catch(onDispatchError)
        .finally(() => setIsDispatching(false));
    },
    [
      fixbooksEventRequest,
      postFixbooksEvent,
      onDispatchError,
      onDispatchSuccess,
      setDispatchError,
      selectedAccountingType,
      isShowingMemo,
      clearAllData,
    ],
  );

  const onMemoChange = useCallback((value: string) => {
    setFixbooksEventRequest(_ => ({
      ..._,
      memo: value,
    }));
  }, []);

  const onDateChanged = useCallback((value: Moment) => {
    setFixbooksEventRequest(_ => ({
      ..._,
      eventTimeMs: value.valueOf(),
    }));
  }, []);

  const onContextChanged = (value: string) => {
    setFixbooksEventRequest(_ => ({
      ..._,
      context: value,
    }));
  };

  const handleMerchantChange = (merchant: Merchant | undefined) => {
    setFixbooksEventRequest(_ => ({
      ..._,
      merchant,
    }));
  };

  const [isFirstActionsGroupValid, setIsFirstActionsGroupValid] = useState(false);
  const [isSecondActionGroupValid, setIsSecondActionGroupValid] = useState(false);

  const canSave = useMemo(() => {
    return !hasCollisionWithinDifferentCashAccrualActions && selectedAccountingType !== 'Different'
      ? isFirstActionsGroupValid
      : isFirstActionsGroupValid && isSecondActionGroupValid;
  }, [
    selectedAccountingType,
    isFirstActionsGroupValid,
    isSecondActionGroupValid,
    hasCollisionWithinDifferentCashAccrualActions,
  ]);

  const onPaste = useCallback(
    (event: BusinessEvent) => {
      console.log('on paste', event);
      setIsShowingMemo(!!event.rawEvent.memo);
      const accountingType = getAccountingType(event);
      setSelectedAccountingType(accountingType);
      setCashActions(event.rawEvent.cashActions);
      setAccrualActions(event.rawEvent.accrualActions);

      const hasMerchant = Boolean(event.rawEvent.merchant);
      const hasContext = Boolean(event.rawEvent.context);
      const hasBoth = hasMerchant && hasContext;
      setFixbooksEventRequest({
        ...event.rawEvent,
        classificationTagId: event.tags.classificationTagId,
        ...(hasMerchant && { merchant: event.rawEvent.merchant }),
        ...(hasContext && { context: event.rawEvent.context }),
      });

      setSelectedContextType(hasBoth ? 'both' : hasMerchant ? 'merchant' : 'text');
    },
    [getAccountingType],
  );

  useEffect(() => {
    console.log('accounting type', selectedAccountingType);
    if (selectedAccountingType === 'Cash') setAccrualActions(getEmptyActions());
    if (selectedAccountingType === 'Accrual') setCashActions(getEmptyActions());
  }, [selectedAccountingType]);

  useEffect(() => {
    setFixbooksEventRequest(_ => ({ ..._, accrualActions }));
    if (selectedAccountingType === 'Both') setFixbooksEventRequest(_ => ({ ..._, cashActions: accrualActions }));
  }, [accrualActions]);

  useEffect(() => {
    setFixbooksEventRequest(_ => ({ ..._, cashActions }));
    if (selectedAccountingType === 'Both') setFixbooksEventRequest(_ => ({ ..._, accrualActions: cashActions }));
  }, [cashActions]);

  const tagsById = useMemo(() => keyBy(classificationTags, 'id'), [classificationTags]);

  useEffect(() => {
    console.log('fixbooks event changed', fixbooksEventRequest);
    if (selectedAccountingType !== 'Different') return;

    const hasCollisions = fixbooksEventRequest.cashActions.some(
      cash =>
        !!fixbooksEventRequest.accrualActions.find(
          accrual =>
            accrual.accountRole === cash.accountRole &&
            accrual.accountContext === cash.accountContext &&
            cash.amount !== accrual.amount,
        ),
    );

    setHasCollisionWithinDifferentCashAccrualActions(hasCollisions);
  }, [fixbooksEventRequest, selectedAccountingType]);

  const merchantContext = (
    <MerchantSelector companyId={companyId} value={fixbooksEventRequest.merchant} onChange={handleMerchantChange} />
  );

  const freeTextContext = (
    <TextField
      sx={{ width: '100%' }}
      label="Context"
      variant="filled"
      value={fixbooksEventRequest.context}
      onChange={e => onContextChanged(e.target.value)}
    />
  );

  return (
    <Container>
      <EditorsWrapper>
        <HorizontalFlex>
          <FormControl variant="standard">
            <InputLabel>Accounting Type</InputLabel>
            <Select
              sx={{
                width: '200px',
              }}
              value={selectedAccountingType}
              label=""
              onChange={_ => setSelectedAccountingType(_.target.value as any)}
            >
              {AccountingTypes.map((accountingType, index) => (
                <MenuItem key={index} value={accountingType}>
                  {accountingType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {isDatePickerVisible && (
            <DatePicker
              inputFormat="yyyy-MM-DD"
              views={['year', 'month', 'day']}
              label="Year-Month-Day"
              onChange={onDateChanged as any}
              value={fixbooksEventRequest.eventTimeMs}
              renderInput={(params: any) => <TextField {...params} helperText={null} />}
            />
          )}
        </HorizontalFlex>

        <HorizontalFlex>
          <FormControl variant="standard">
            <InputLabel>Context Type</InputLabel>
            <Select
              sx={{
                width: '200px',
              }}
              value={selectedContextType}
              label=""
              onChange={_ => {
                setSelectedContextType(_.target.value as any);
                setFixbooksEventRequest(_ => ({
                  ..._,
                  merchant: undefined,
                  context: undefined,
                }));
              }}
            >
              <MenuItem value={'text'}>Free text</MenuItem>
              <MenuItem value={'merchant'}>Merchant</MenuItem>
              <MenuItem value={'both'}>Merchant & Free text</MenuItem>
            </Select>
          </FormControl>
          <Box display="flex" gap="10px" width="100%" maxWidth={600}>
            {selectedContextType === 'merchant' ? (
              merchantContext
            ) : selectedContextType === 'text' ? (
              freeTextContext
            ) : (
              <>
                {merchantContext}
                {freeTextContext}
              </>
            )}
          </Box>
        </HorizontalFlex>

        <Autocomplete
          autoHighlight
          sx={{ width: 300 }}
          handleHomeEndKeys
          value={fixbooksEventRequest.classificationTagId ? tagsById[fixbooksEventRequest.classificationTagId] : null}
          onChange={(_event, tag) => {
            setFixbooksEventRequest(req => ({
              ...req,
              classificationTagId: tag?.id,
            }));
          }}
          options={classificationTags || []}
          renderInput={(params: any) => <TextField {...params} label="Classification Tag" variant="filled" />}
          renderOption={(props, tag) => {
            const { key, ...optionProps } = props as OutlinedTextFieldProps;
            return (
              <Box key={key} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...(optionProps as any)}>
                {tag.name}
              </Box>
            );
          }}
          getOptionLabel={option => option.name}
          selectOnFocus
        />

        {selectedAccountingType !== 'Different' ? (
          <EventEditor
            actionsToEdit={
              selectedAccountingType === 'Both' || selectedAccountingType === 'Cash' ? cashActions : accrualActions
            }
            editActions={
              selectedAccountingType === 'Both' || selectedAccountingType === 'Cash'
                ? setCashActions
                : setAccrualActions
            }
            type={selectedAccountingType}
            onValidationChanged={setIsFirstActionsGroupValid}
          />
        ) : (
          <>
            <EventEditor
              actionsToEdit={cashActions}
              editActions={setCashActions}
              type={'Cash'}
              onValidationChanged={setIsFirstActionsGroupValid}
            />
            <EventEditor
              actionsToEdit={accrualActions}
              editActions={setAccrualActions}
              type={'Accrual'}
              onValidationChanged={setIsSecondActionGroupValid}
            />
          </>
        )}
      </EditorsWrapper>

      <VerticalFlex>
        <FormControlLabel
          sx={{ display: 'flex', alignSelf: 'flex-start' }}
          control={<Switch checked={isShowingMemo} onChange={_ => setIsShowingMemo(_.target.checked)} />}
          label="With memo"
        />
        {isShowingMemo && (
          <MultiLineTextInput value={fixbooksEventRequest.memo} placeholder="Your memo" onChange={onMemoChange} />
        )}
        {hasCollisionWithinDifferentCashAccrualActions && (
          <>Same roles/contexts in both Cash and Accrual have different amounts</>
        )}
        <ActionButtons onSaveClicked={onSaveClicked} isLoading={isDispatching} canSave={canSave} onPaste={onPaste} />
      </VerticalFlex>
      <Toast
        type={dispatchError ? 'error' : 'success'}
        message={dispatchError || (showSuccess ? SUCCESS_MESSAGE : undefined)}
      />
    </Container>
  );
};
