import styled from '@emotion/styled';
import { FixbooksAction } from '@finaloop/fixbooks-types';
import { Autocomplete, TextField } from '@mui/material';
import { useMemo, useState } from 'react';
import { getEventAccountingType } from '../../hooks/useAccountingTypeFinder';
import { useGetFixbooksEvents } from '../../hooks/useFixbooksApi';
import { AccountingType, BusinessEvent } from '../../types';
import { timeInMsToFriendlyTime } from '../../utils';
import { Loader } from '../BusinessEventsDispatcherView/Loader';
import { BusinessEventItem } from './BusinessEventItem';
import { ClassificationTag } from '../../../../services/FinaloopGql';
import { keyBy } from 'lodash';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HeaderTitle = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

const actionsToTexts = (actions: FixbooksAction[]): string[] =>
  actions
    .map(Object.values)
    .filter(Boolean)
    .map(_ => _.toString());

const cashAndAccrualTypes: AccountingType[] = ['Cash', 'Accrual'];
const doesEventContainSearch = (event: BusinessEvent, filterText: string): boolean => {
  const { rawEvent } = event;
  const { merchant, eventTimeMs, cashActions, accrualActions, memo } = rawEvent;
  const { name: merchantName = '' } = merchant || {};

  if (timeInMsToFriendlyTime(eventTimeMs).includes(filterText)) return true;

  const type = getEventAccountingType(event);
  const textsToSearchAt = [
    ...actionsToTexts(cashActions),
    ...actionsToTexts(accrualActions),
    memo?.toLowerCase() || '',
    type.toLowerCase(),
    ...(type === 'Different' || type === 'Both' ? cashAndAccrualTypes : []),
    merchantName,
  ];

  return textsToSearchAt.some(_ => _.toLowerCase().includes(filterText.toLowerCase()));
};

const sortEvents = (_: BusinessEvent, __: BusinessEvent) => __.rawEvent.eventTimeMs - _.rawEvent.eventTimeMs;

export const BusinessEventList = ({ classificationTags }: { classificationTags: ClassificationTag[] }) => {
  const { fixbooksEvents, isError, isLoading } = useGetFixbooksEvents();
  const [textsToFilter, setTextsToFilter] = useState<string[]>([]);

  const eventsToRender = () => {
    if (!fixbooksEvents?.length) return [];
    if (!textsToFilter.length) return fixbooksEvents;
    return fixbooksEvents.filter(
      event => !textsToFilter.some(filterTextWord => !doesEventContainSearch(event, filterTextWord)),
    );
  };

  const sortedEventsToRender = eventsToRender().sort(sortEvents);
  const tagById = useMemo(() => keyBy(classificationTags, 'id'), [classificationTags]);

  if (isError) return <>Something went wrong getting previous events.</>;

  return (
    <Container>
      <Header>
        <HeaderTitle>
          History: ({sortedEventsToRender.length})
          {isLoading && (
            <>
              <Loader /> Refreshing...
            </>
          )}
        </HeaderTitle>
        <Autocomplete
          multiple
          freeSolo
          onChange={(_, newValue) => setTextsToFilter(newValue)}
          options={[] as string[]}
          defaultValue={[]}
          value={textsToFilter}
          renderInput={params => (
            <TextField
              {...params}
              label="Free text search"
              placeholder="type and click enter, e.g. cash, both, 18/01, 02"
            />
          )}
          sx={{ width: '500px' }}
        />
      </Header>
      {sortedEventsToRender.map((event, index) => (
        <BusinessEventItem
          key={index}
          event={event}
          classificationTag={event.tags.classificationTagId ? tagById[event.tags.classificationTagId] : undefined}
        />
      ))}
    </Container>
  );
};
