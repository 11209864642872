import { useMemo } from 'react';
import { useListAccountMetaData } from '../../common/hooks/useListAccountMetaData';

const DEPRECATED_ACCOUNT_ROLES = new Set([
  'Cost of goods sold',
  'Packaging supplies',
  'Shipping & freight-in',
  'Loan',
  'Long-term loan payable',
  'Loan receivable',
  'Long-term loan receivable',
  'Line of credit',
  'Long-term related party loan payable',
  'Related party loan payable - non interest bearing',
  'Related party loan receivable',
  'Related party balance - non interest bearing',
  'Related party loan - non interest bearing',
  'Related party loan',
  'Due-to-from owner',
  'Due-to-from related party',
  'Due-to employee',
  'Due-to contractor',
  'CLA',
  'Related party CLA',
  'Accrued interest',
  'Accrued interest receivable',
  'Long-term accrued interest receivable',
  'Related party accrued interest',
  'Related party accrued interest payable',
  'Long term related party accrued interest payable',
  'Related party accrued interest receivable',
  'Long term related party accrued interest receivable',
  "Owner's draw",
]);

export const useAccountRoles = () => {
  const { data: accountMetaData } = useListAccountMetaData();

  const roles = useMemo(
    () =>
      accountMetaData
        .map(({ accountRole }) => accountRole)
        .filter(accountRole => !DEPRECATED_ACCOUNT_ROLES.has(accountRole)),
    [accountMetaData],
  );

  return { roles };
};
